import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import abi from "../utils/coolcrocs.json";


const Mint = () => {
  const [currentAccount, setCurrentAccount] = useState("");
  
  const checkWallet = async () => {
    const { ethereum } = window;

    if (!ethereum) {
        console.log("Make sure you have metamask!");
        return;
    } else {
        console.log("We have the ethereum object", ethereum);
    }
    const accounts = await ethereum.request({ method: 'eth_accounts' });
    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account:", account);
    } else {
      console.log("No authorized account found")
    }
  }

  const connectWallet = async () => {
    try {
      const { ethereum } = window;
      if (!ethereum) {
        alert("Get MetaMask!");
        return;
      }
      const accounts = await ethereum.request({ method: "eth_requestAccounts" });
      console.log("Connected", accounts[0]);
      setCurrentAccount(accounts[0]); 
    } catch (error) {
      console.log(error)
    }
  }


  const mintNFT = async () => {
    const CONTRACT_ADDRESS = "0xe566733717A37b2F157255A19DED6022B11EC9Fd";
    const CONTRACT_ABI = abi;
    try {
      const { ethereum } = window;
  
      if (ethereum) {
        const value = document.getElementById("mintSelector").value;
        var totalPrice = (value * 0.077).toString();
        console.log(totalPrice);
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
        let nftTxn = await connectedContract.publicSale(currentAccount, value, {value: ethers.utils.parseEther(`${totalPrice}`)});
        await nftTxn.wait();
        alert("Minted NFT");
      } else {
      }
    } catch (error) {
      console.log(error)
    }
  }

  const renderNotConnectedContainer = () => (
    <div className="flex flex-row flex-wrap items-center justify-around">
        <div>
          <button onClick={connectWallet} className="relative bg-gray-800 text-white p-3 rounded-lg text-sm uppercase font-semibold tracking-tight overflow-visible">
            Connect Your Wallet
          </button>
        </div>
      </div>
  );

  useEffect(() => {
    checkWallet();
  }, [])

  return (
    <div id="mint" className="background flex flex-row flex-wrap items-center justify-around p-10">
        <div className="p-4">
            <p className="text-center text-3xl font-bold text-gray-800">
                Mint a Croc
            </p>
            <p className="text-center mb-10 text-xl font-normal text-gray-500">
              Public Sale - Price 0.077 ETH
            </p>
            {currentAccount === "" ? (
            renderNotConnectedContainer()
            ) : (
            <div className="flex flex-row flex-wrap items-center justify-around">
              <div className="p-8">
                {/* create a number selector */}
                <div className="flex flex-row flex-wrap items-center justify-around">
                  <div className="p-4">
                    <p className="text-center text-xl font-normal text-gray-500">
                      Amount
                    </p>
                    <select id="mintSelector" className="text-center mx-auto inset-x-0 p-4">
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                      <option value={9}>9</option>
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option value={20}>20</option>
                    </select>
                  </div>
                </div>
                <button onClick={mintNFT} className="relative bg-gray-800 text-white p-3 rounded-lg text-sm uppercase font-semibold tracking-tight overflow-visible">
                  Mint Now
                </button>
              </div>
            </div>
            )}
        </div>
    </div>
  );
};

export default Mint;
