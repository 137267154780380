import React from 'react';

const Story = () => {
    return (
        <div id="story" className="container mx-auto">
        <div className="p-8">
            <p className="text-center p-4 text-3xl font-bold text-gray-800">
                The Story
            </p>
            <p className="text-center mb-10 text-xl font-normal text-gray-500">
            The Cool Crocs story takes place in The Swamp. An isolated area fenced in on all sides, run by the rich AristoCrocs who enjoy watching the lower class Crocs entertain them, but don’t give them any upward mobility. The Croc population has grown.</p>
            <p className="text-center mb-10 text-xl font-normal text-gray-500">
            Some of the Crocs activities no longer entertain the AristoCrocs, such as HipHop, skateboarding, and graffiti. What’s worse is that they are starting to discuss leaving the swamp or changing the power dynamic that keeps the AristoCrocs in charge and provides them with their affluent lifestyle.
            </p> 
            <p className="text-center mb-10 text-xl font-normal text-gray-500">
            The chemicals will either kill or mutate the Crocs (think TGRI’s ooze in TMNT), a win-win for the AristoCrocs. They either cut the population down, or have mutated monster Crocs which will be even better to watch play football and other sports.
            </p>
            <p className="text-center mb-10 text-xl font-normal text-gray-500">
            So the AristoCrocs have arranged a deal with a nearby chemical company. They will allow the company to dump their waste into the swamp in exchange for a large sum of money that will allow the AristoCrocs to stay in power, and even hire a military to protect them in case the Crocs try to start a revolution.
            </p>
            <p className="text-center mb-10 text-xl font-normal text-gray-500">
            However, one Croc does escape. He tells nearby beavers about this plan, and the beavers agree to build dams to stop the chemical runoff from spreading through the swamp.
            </p>
        </div>
        <div className="grid grid-rows-2 grid-flow-row">
            <div className="bg-white shadow-lg rounded m-8 p-8 row">
                <div className="sm:w-auto">
                    <h3 className="text-3xl font-bold text-gray-800">Skater Gators</h3>
                    <p className="text-grey-dark font-thin text-sm leading-normal md:text-white">
                    Cool Crocs is heavily inspired by skateboarding culture as the artist/founder is a lifetime skateboarder. Expect a skateboard deck drop and partnerships with organizations that use skateboarding to empower communities around the globe.
                    </p>
                </div>
            </div>
            <div className="bg-white shadow-lg rounded m-8 p-8 row">
                <div className="sm:w-auto">
                    <h3 className="text-3xl font-bold text-gray-800">Hip Hop Crocs</h3>
                    <p className="text-grey-dark font-thin text-sm leading-normal md:text-white">
                    Cool Crocs is heavily inspired by Hip Hop culture as the artist/founder is a Rapper and DJ. Expect music drops and partnerships with underground Hip Hop artists, and organizations like HipHopForChange.com which educates youth about social justice issues through the Hip Hop’s elements, such as graffiti, beat making, rapping and break dance.
                    </p>
                </div>
            </div>
            <div className="bg-white shadow-lg rounded m-8 p-8 row">
                <div className="sm:w-auto">
                    <h3 className="text-3xl font-bold text-gray-800">Croc Jocks</h3>
                    <p className="text-grey-dark font-thin text-sm leading-normal md:text-white">
                    We love sports! Whether it is Football, Soccer, Basketball or Golf, this project aims to unite fans and celebrate our teams!
                    </p>
                </div>
            </div>
            <div className="bg-white shadow-lg rounded m-8 p-8 row">
                <div className="sm:w-auto">
                    <h3 className="text-3xl font-bold text-gray-800">Party Crocs</h3>
                    <p className="text-grey-dark font-thin text-sm leading-normal md:text-white">
                    We love to party. Whether it is Virtual Reality, Gaming, or just having fun, Party Crocs enjoy the joy of being part of the community.                    
                    </p>
                </div>
            </div>
            <div className="bg-white shadow-lg rounded m-8 p-8 row">
                <div className="sm:w-auto">
                    <h3 className="text-3xl font-bold text-gray-800">AristoCrocs</h3>
                    <p className="text-grey-dark font-thin text-sm leading-normal md:text-white">
                    The AristoCrocs are the ruling class in the Swamp, and they want it to stay that way. As the antagonists of the series, they devise ways to keep the rest of the Crocs in their place. 
                    </p>
                </div>
            </div>
        </div>
        </div>
      );

    }

export default Story;