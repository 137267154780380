import React from "react";

const products = [
    {
      id: 1,
      name: 'Cool Crocs Holders Tee',
      href: '#merch',
      imageSrc: 'https://cdn.discordapp.com/attachments/931332384698282035/940315791063146516/53CBD88D-314A-4B89-BB1E-548FD1786CEA.jpg',
      imageAlt: "CoolCrocs Holders Tee",
      price: 'Prototype',
      color: 'Black',
    },
    {
      id: 2,
      name: 'Cool Crocs Work Tee',
      href: '#merch',
      imageSrc: 'https://cdn.discordapp.com/attachments/931332384698282035/940315791314812928/A5986BF2-F265-4464-95C9-02B7AFCEAD0F.jpg',
      imageAlt: "Cool Croc Work Tee",
      price: 'Prototype',
      color: 'White',
    },
    {
      id: 3,
      name: 'DJDimplz Style',
      href: '#merch',
      imageSrc: 'https://cdn.shopify.com/s/files/1/1234/1684/products/qLLYZwIIQDiDiR7ix0yI_460x.png?v=1611702330',
      imageAlt: "DJDimplz Style",
      price: 'Prototype',
      color: 'Multi-Color',
    },
    {
      id: 4,
      name: 'Cool Crocs Colors Sweatpants',
      href: '#merch',
      imageSrc: 'https://cdn.shopify.com/s/files/1/1234/1684/products/YZL5daZzQ26z34H4qaaT_460x.png?v=1643443621',
      imageAlt: "Cool Crocs Colors sweatpant",
      price: 'Prototype',
      color: 'Mutli-Color',
    },
  ]
  
  export default function Clothing() {
    return (
      <div id="merch" className="bg-white">
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-center text-3xl font-bold text-gray-800">
                Cool Crocs Merchandise
            </h2>
            <h1 className="text-center text-xl font-normal text-gray-500">
                All NFT holders are entitled to redeem their Cool Crocs for cool stuff.
            </h1>
          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {products.map((product) => (
              <div key={product.id} className="group relative">
                <div className="w-full min-h-80 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="w-32 h-32 object-center object-cover lg:w-full lg:h-full"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-sm text-gray-700">
                      <a href={product.href}>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {product.name}
                      </a>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                  </div>
                  <p className="text-sm font-medium text-gray-900">{product.price}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
