import React from "react";
import photo1 from "../assets/teamAssets/jp.png";
import photo2 from "../assets/teamAssets/wes.png";
import photo3 from "../assets/teamAssets/mod.png";
import photo4 from "../assets/teamAssets/ian.png";
const Team = () => {
  return (
    <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-12">
        <div id="team" className="text-center pb-12">
            <h2 className="text-center text-3xl font-bold text-gray-800">
                The NFTeam
            </h2>
            <h1 className="text-center text-xl font-normal text-gray-500">
                Check out our awesome team members
            </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="w-full bg-white rounded-lg sahdow-lg overflow-hidden flex flex-col justify-center items-center">
            <div>
                <a href="https://www.twitter.com/jpz_jaypeezy" target="_blank" rel="noopener noreferrer">
                <img className="object-center object-cover h-auto w-full" src={photo1} alt="JPZ"/>
                </a>
            </div>
            <div className="text-center py-8 sm:py-6">
                <p className="text-xl text-gray-700 font-bold mb-2">Joseph Penner</p>
                <p className="text-base text-gray-400 font-normal">Artist / Founder</p>
            </div>
        </div>
            <div className="w-full bg-white rounded-lg sahdow-lg overflow-hidden flex flex-col justify-center items-center">
                <div>
                    <a href="https://www.twitter.com/wesmedwards" target="_blank" rel="noopener noreferrer">
                    <img className="object-center object-cover h-auto w-full" src={photo2} alt="Wes"/>
                    </a>
                </div>
                <div className="text-center py-8 sm:py-6">
                    <p className="text-xl text-gray-700 font-bold mb-2">Wes</p>
                    <p className="text-base text-gray-400 font-normal">Business Management</p>
                </div>
            </div>
            <div className="w-full bg-white rounded-lg sahdow-lg overflow-hidden flex flex-col justify-center items-center">
                <div>
                    <a href="https://www.twitter.com/6missedcalls" target="_blank" rel="noopener noreferrer">
                    <img className="object-center object-cover h-auto w-full" src={photo3} alt="Ian"/>
                    </a>
                </div>
                <div className="text-center py-8 sm:py-6">
                    <p className="text-xl text-gray-700 font-bold mb-2">Ian Perez</p>
                    <p className="text-base text-gray-400 font-normal">Developer</p>
                </div>
            </div>
            <div className="w-full bg-white rounded-lg sahdow-lg overflow-hidden flex flex-col justify-center items-center">
                <div>
                    <a href="https://www.twitter.com/bradscracked" target="_blank" rel="noopener noreferrer">
                    <img className="object-center object-cover h-auto w-full" src={photo4} alt="bradscracked"/>
                    </a>
                </div>
                <div className="text-center py-8 sm:py-6">
                    <p className="text-xl text-gray-700 font-bold mb-2">Bradley Ruminer</p>
                    <p className="text-base text-gray-400 font-normal">Community Manager</p>
                </div>
            </div> 
        </div>
    </section>
    );
};

export default Team;
