import React from "react";

const Footer = ({logo}) => {
  return (
    <div id="contact" className="flex flex-row flex-wrap items-center justify-around p-10">
      <a href="/" className="w-1/2 pl-5 mb-2 lg:visible lg:w-auto lg:pl-0 sm:visible sm:w-auto lg:mb-0">
        <img src={logo} height="256px" width="256px" alt="logo"/>
      </a>
      <div className="flex items-center justify-around w-2/3 pt-5 lg:pt-0 lg:w-1/3 ">
        <a href="https://www.twitter.com/coolcrocsnft">
            <svg className="text-green-600 hover:text-green-700" width="30" height="30" viewBox="0 0 35 29" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.145 4.1787C32.9202 4.70704 31.5873 5.08729 30.2144 5.23539C31.6398 4.38858 32.7072 3.05077 33.2164 1.47295C31.8788 2.26862 30.4136 2.82656 28.8855 3.12202C28.2469 2.43928 27.4745 1.89538 26.6165 1.52421C25.7585 1.15304 24.8332 0.962551 23.8983 0.964616C20.1159 0.964616 17.0739 4.03061 17.0739 7.79305C17.0739 8.32139 17.1379 8.84973 17.242 9.35806C11.5783 9.06187 6.52705 6.35611 3.16887 2.21343C2.55697 3.25857 2.23632 4.44858 2.24026 5.65966C2.24026 8.0292 3.44505 10.1186 5.28224 11.3474C4.19955 11.3047 3.14222 11.0071 2.19624 10.4788V10.5628C2.19624 13.881 4.54176 16.6308 7.66779 17.2632C7.08084 17.4157 6.47704 17.4936 5.87062 17.4953C5.42633 17.4953 5.00606 17.4513 4.58178 17.3913C5.44634 20.097 7.96398 22.0623 10.9619 22.1263C8.6164 23.9635 5.67849 25.0442 2.48843 25.0442C1.91605 25.0442 1.38771 25.0242 0.839355 24.9602C3.86532 26.9014 7.45565 28.0222 11.3222 28.0222C23.8743 28.0222 30.7428 17.6234 30.7428 8.59757C30.7428 8.30138 30.7428 8.00518 30.7227 7.70899C32.0516 6.73636 33.2164 5.53158 34.145 4.1787Z" fill="currentColor"/></svg>
        </a>
        <a href="https://discord.gg/ZuqQvvPbrS">
          <svg className="bi bi-discord text-green-600 hover:text-green-700" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#16A34A" viewBox="0 0 16 16">
            <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
          </svg>
        </a>
      </div>
      <div className="mt-10 text-lg text-green-800:text-green-700 lg:mt-0">
        <a href="https://www.twitter.com/6missedcalls">
          <span className="text-black-600 hover:text-green-700">ShadowySuperCoder</span>
        </a>
      </div>
    </div>
  );
};

export default Footer;
