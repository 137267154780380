import React from "react";

import mobileVideo from "../assets/theSwampMobile.mp4";
import desktopVideo from "../assets/theSwampDesktop.webm";

const Main = () => {
    const screenSize = window.innerWidth;
    let videoSize = "";
    if (screenSize < 500) {
        return(
            <div id="home">
                <header className="relative flex items-center justify-center h-48">
                    <video
                        autoPlay={true}
                        loop={true}
                        controls={false} 
                        muted
                        playsInline
                        className="absolute z-1 top-0 left-0 w-full h-full object-cover object-center"
                    >
                        <source
                        src={mobileVideo}
                        type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </header>
            </div>
        );
        } else {
            return (
                <div>
                    <div id="home">
                        <header className="relative flex items-center justify-center h-screen mb-12 overflow-hidden">
                            <video
                                autoPlay={true}
                                loop={true}
                                controls={false} 
                                muted
                                className={videoSize}
                            >
                                <source
                                src={desktopVideo}
                                type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </video>
                        </header>
                    </div>
                </div>
            );
        }
};

export default Main;
