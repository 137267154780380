import React from 'react'

function About() {
  return (
    <div id="story" className="container mx-auto">
        <div className="p-8">
        <p className="text-center p-4 text-3xl font-bold text-gray-800">
                About Us
            </p>
            <p className="text-center mb-10 text-xl font-normal text-gray-500">
            The Swamp is the entry point to an expansive DAO driven cartoon universe. As the episodes unfold new characters will be introduced. The DAO will vote which characters’ stories to follow in a choose-your-own-adventure style animated series.
            </p>

            <p className="text-center p-4 text-3xl font-bold text-gray-800">
               Roadmap & Utility
            </p>

            <p className="text-center mb-10 text-xl font-normal text-gray-500">
                <li>
                Access to multi-media immersive art events
                </li>
                <li>
                Partnerships with brands for IRL savings
                </li>
                <li>
                Privately Hosted Events
                </li>
                <li>
                Metaverse events
                </li>
                <li>
                Clothing Line
                </li>
                <li>
                Gaming
                </li>
            </p>
        </div>
    </div>
  )
}

export default About