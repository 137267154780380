import './App.css';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import logo from "./assets/logo.svg";
import Main from './components/Main';
import Mint from './components/Mint';
import Team from './components/Team';
import Clothing from './components/Clothing';
import Story from "./components/Story";
import About from './components/About';

function App() {
  return (    
    <div>
        <div className="flex flex-col">
        <Navbar logo={logo}/>
        <Main />
        <Mint />
        <div id="divider" className="rounded-full ring-2 ring-gray-200 lg:w-1/2 lg:mx-auto "></div>
        <About />
        <Story />
        <div id="divider" className="rounded-full ring-2 ring-gray-200 lg:w-1/2 lg:mx-auto "></div>
        <Team />
        <div id="divider" className="rounded-full ring-2 ring-gray-200 lg:w-1/2 lg:mx-auto "></div>
        <Clothing />
        <div id="divider" className="rounded-full ring-2 ring-gray-200 lg:w-1/2 lg:mx-auto "></div>
        <Footer logo={logo}/>
      </div>
    </div>
  );
}

export default App;
